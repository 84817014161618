import axios from 'axios'

// Your backend API URL
const apiUrl = process.env.REACT_APP_API_URL

export { setupAxiosInterceptors }

function setupAxiosInterceptors() {
  // Intercept requests
  axios.interceptors.request.use(
    (config) => {
      // Add your authentication logic if needed
      // config.headers['Authorization'] = 'Bearer your-auth-token';
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  // Intercept responses
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access, e.g., redirect to login page
        // or refresh token if you are using JWT
      }
      return Promise.reject(error)
    },
  )
}

// Authentication function using Axios
export function authenticate(userData) {
  return axios
    .post(`${apiUrl}/auth/authenticate`, userData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

// Authentication function using Axios
export function sendPassword(email) {
  return axios
    .post(`${apiUrl}/auth/generate-password`, { email })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function createUser(userData) {
  return axios
    .post(`${apiUrl}/user`, userData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function updateUser(userData) {
  console.log('🚀 ~ updateUser ~ userData:', userData)
  return axios
    .put(`${apiUrl}/user/${userData.id}`, userData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function getUser(id) {
  return axios
    .get(`${apiUrl}/user/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

// Get users function using Axios
export function getUsers() {
  return axios
    .get(`${apiUrl}/users`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function createTemplate(templateData) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return axios
    .post(`${apiUrl}/uploader`, templateData, config)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function reactivateTemplate(templateData) {
  return axios
    .post(`${apiUrl}/reactivate_template`, templateData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function updateTemplate(templateData) {
  console.log('🚀 ~ updateTemplate ~ templateData:', templateData)
  return axios
    .put(`${apiUrl}/template/${templateData.id}`, templateData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function getTemplates() {
  return axios
    .get(`${apiUrl}/templates`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function getProducts() {
  return axios
    .get(`${apiUrl}/products`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function getProjects() {
  return axios
    .get(`${apiUrl}/projects`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function createProject(projectData) {
  return axios
    .post(`${apiUrl}/projects`, projectData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export function updateProject(projectData) {
  return axios
    .put(`${apiUrl}/projects/${projectData.id}`, projectData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}
